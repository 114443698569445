<template>
  <v-layout wrap>
<v-flex>
    <span>hii</span>
</v-flex>

  </v-layout>
</template>

<script>
export default {

}
</script>

<style>

</style>